<template>
	<div class="solution">
		<div class="container">
			<div class="title">
				<h2>解决方案</h2>
				<p>SOIUTION</p>
			</div>
			<div class="carousel">
				<div class="swiper-button-prev"></div>
				<!--左箭头。如果放置在swiper外面，需要自定义样式。-->
				<div class="swiper-container">
					<div class="swiper-wrapper">
						<div
							class="swiper-slide"
							v-for="(item, index) in solutionList"
							:key="index"
							:style="{ 'z-index': solutionList.length - index }"
						>
							<div class="s-box">
								<div class="b-box">
									<div class="s-title">{{ item.title }}</div>
									<div class="s-intro">
										{{ item.intro }}
									</div>
									<div class="s-img">
										<img :src="item.img" alt="" />
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="swiper-button-next"></div>
				<!--右箭头。如果放置在swiper外面，需要自定义样式。-->
			</div>
		</div>
	</div>
</template>
<script>
export default {
	name: 'solution',
	data() {
		return {
			solutionList: [
				{
					title: '智慧医疗',
					intro: '以“数字孪生”医院为基础，打造 覆盖智慧医疗、智慧服务、智慧 管理的全方位智慧医院。',
					img: require('@/assets/images/solution1.webp')
				},
				{
					title: '智慧楼宇',
					intro: '推动楼宇全要素数字化、可视化， 万物互联、实时感知，管控精准， 风险可预警，问题可追溯。',
					img: require('@/assets/images/solution2.webp')
				},
				{
					title: '智慧园区',
					intro: '覆盖园区招商租赁、物业管理、 零碳运营全生命周期，全面推进 园区数字化、智慧化管理。',
					img: require('@/assets/images/solution3.webp')
				},
				{
					title: '智慧校园',
					intro: '推动数字化校园建设，以校园管理决策为中心的主题规划，大数据辅助决策管理。',
					img: require('@/assets/images/solution4.webp')
				},
				{
					title: '智慧社区',
					intro: '基于数字孪生社区打造全面管理、 精准服务、持续运营、优居惠民 的智慧社区样板。',
					img: require('@/assets/images/solution5.webp')
				},
				{
					title: '智慧农业',
					intro:
						'农业生产、经营、流通、服务等领域的数字化设计、可视化表达、智能控制和系统化管理有机结合，实现农业资源的合理利用，降低生产成本， 提高经营效率，改善生态环境。',
					img: require('@/assets/images/solution6.webp')
				},
				{
					title: '智慧矿山',
					intro:
						'贯穿于矿山规划、生产、经营和 管理的全过程，保障矿山的科学 决策和智慧管理；主动感知、自动分析、快速处理。',
					img: require('@/assets/images/solution7.webp')
				},
				{
					title: '智慧工业',
					intro: '工业互联网+全要素数字化、可视化，推进工厂向智造时代迈进。',
					img: require('@/assets/images/solution8.webp')
				}
			]
		}
	},
	mounted() {
		this.swiperInit()
	},
	methods: {
		// 轮播初始化
		swiperInit() {
			let swiper = new Swiper('.solution .swiper-container', {
				loop: false, //循环
				// autoplay: {
				// 	delay: 5000
				// },
				slidesPerView: 4,
				grid: {
					fill: 'column',
					rows: 1
				},
				navigation: {
					nextEl: '.swiper-button-next',
					prevEl: '.swiper-button-prev'
				}
			})
		}
	}
}
</script>
<style lang="less" scoped>
.container {
	min-width: 1300px;
	width: 100%;
	margin: 0 auto;
}
.solution {
	position: relative;
	.title {
		font-family: 'PingFang-SC-Bold';
		padding: 40px 0;
		text-align: center;
		h2 {
			font-size: 40px;
			color: #333;
		}
		p {
			font-size: 20px;
			color: #3370ff;
		}
	}
	.carousel {
		width: 100%;
		height: 600px;
		position: relative;
		display: flex;
		justify-content: center;
		.swiper-container {
			box-shadow: 0 0 30px rgba(232, 233, 239, 1);
			width: 1200px;
			margin-left: 2.3%;
			margin-right: 2.3%;
			height: 100%;
			overflow: hidden;
			.swiper-slide {
				position: relative;
			}
			/deep/.swiper-pagination-bullet {
				width: 50px !important;
				height: 5px !important;
				border-radius: 5px;
				background-color: rgba(0, 0, 0, 0.5) !important;
				opacity: 1;
			}
			/deep/ .swiper-pagination-bullet-active {
				background-color: rgba(255, 255, 255, 1) !important;
			}
		}
		.swiper-button-prev {
			position: relative;
			width: 30px;
			height: 30px;
			background: url('~@/assets/images/prev.png') no-repeat;
			&:after {
				content: '';
			}
			&:hover {
				background: url('~@/assets/images/prev1.png') no-repeat;
			}
		}
		.swiper-button-next {
			position: relative;
			width: 30px;
			height: 30px;
			background: url('~@/assets/images/next.png') no-repeat;
			&:after {
				content: '';
			}
			&:hover {
				background: url('~@/assets/images/next1.png') no-repeat;
			}
		}
		.s-box {
			width: 100%;
			height: 100%;
			box-shadow: 0 0 30px rgba(232, 233, 239, 1);
			overflow: hidden;
			cursor: pointer;

			.b-box {
				width: 100%;
				height: 100%;
				padding: 100px 0 0 20px;
				position: relative;
				box-sizing: border-box;
				.s-title {
					font-family: 'PingFang-SC-Medium';
					font-size: 30px;
					color: #333;
					padding-bottom: 10px;
					position: relative;
					display: inline-block;
					&:before {
						content: '';
						width: 60px;
						height: 5px;
						background-color: #2b80f4;
						position: absolute;
						bottom: 0;
						transition: all 0.2s;
					}
				}
				.s-intro {
					font-family: 'PingFang-SC-Medium';
					font-size: 18px;
					width: 250px;
					color: #666;
					margin-top: 20px;
				}
				.s-img {
					width: 100%;
					height: 100%;
					position: absolute;
					left: 0;
					top: 0;
					z-index: -1;
					transition: all 0.2s;
					img {
						width: 100%;
					}
				}
			}
			&:hover {
				.s-img {
					transform: scale(1.2);
				}
				.s-title {
					&:before {
						width: 100%;
					}
				}
			}
		}
	}
}
</style>
