<template>
	<div class="back-ground-picture">
		<div class="back-ground-text">联系我们，领取您的专属解决方案</div>
		<div class="back-ground-photo clearfix">
			<div class="back-ground-from clearfix">
				<div class="from-input-picture">
					<div class="username">
						<span class="username-top">姓</span>
						<span class="username-last">名</span>
						<input class="inputUserName" v-model="userInfo.userName" type="text" placeholder="请输入姓名" />
					</div>
					<div class="phoneNumber">
						<span class="spanPhoneNumber">手机号</span>
						<input
							ref="phoneNumberRef"
							class="inputPhoneNumber"
							v-model="userInfo.phone"
							type="text"
							placeholder="请输入手机号"
						/>
					</div>
					<div class="checkCode">
						<span class="spanCheckCode">验证码</span>
						<input class="inputCheckCode" v-model="userInfo.checkCode" type="text" placeholder="请输入验证码" />
						<button type="button" class="sendCheckCode" :disabled="getCodeBtnDisable" @click="getCode">
							{{ codeBtnWord }}
						</button>
					</div>
					<div class="li-ji">
						<!-- 解决button按钮在form表单下,自动刷新的问题 -->
						<button type="button" class="button-class" @click="allDate">立即领取</button>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import axios from 'axios'

export default {
	name: 'phoneAndUser',
	data() {
		return {
			userInfo: {
				userName: '',
				phone: '',
				checkCode: ''
			},
			//获取验证码按钮失效的时间
			waitTime: 61,
			//获取验证码
			codeBtnWord: '获取验证码',
			//是否可以点击
			getCodeBtnDisable: false,
			timer: null
		}
	},
	methods: {
		//立即领取
		async allDate() {
			if (this.userInfo.userName === '') {
				alert('姓名不允许为空！')
				return false
			} else {
				let userName = this.userInfo.userName
				var reg = /[^\u4e00-\u9fa5]/
				if (reg.test(userName)) {
					alert('姓名只允许汉字！')
					return false
				}
			}
			if (this.userInfo.phone === '') {
				alert('手机号不允许为空！')
				return false
			}
			if (this.userInfo.checkCode === '') {
				alert('验证码不允许为空！')
				return false
			}
			if (!/^((1[3,5,8,7,9][0-9])|(14[5,7])|(17[0,6,7,8])|(19[1,7]))\d{8}$/.test(this.userInfo.phone)) {
				this.$refs.phoneNumberRef.style.borderColor = 'red'
				this.getCodeBtnDisable = false
				return false
			} else {
				this.$refs.phoneNumberRef.style.borderColor = ''
			}
			let params = {
				phone: this.userInfo.phone,
				userName: this.userInfo.userName,
				verificationCode: this.userInfo.checkCode
			}
			const { data } = await axios.post('https://www.sdxinr.com/xrOfficialWebsite/appointmentUser/saveUser', params)
			if (data.code === 200) {
				alert('领取成功！')
			} else {
				alert(data.msg)
			}
			this.userInfo = {
				userName: '',
				phone: '',
				checkCode: ''
			}
			clearInterval(this.timer)
			this.codeBtnWord = '获取验证码'
		},

		//发送验证码
		async sendCode() {
			// let data = await axios.post('/sendMessage', params)
		},

		//验证码发送和手机号效验
		getCode() {
			this.sendCode()
			if (this.userInfo.phone === '') {
				this.$refs.phoneNumberRef.style.borderColor = 'red'
				this.getCodeBtnDisable = false
			} else {
				if (!/^((1[3,5,8,7,9][0-9])|(14[5,7])|(17[0,6,7,8])|(19[1,7]))\d{8}$/.test(this.userInfo.phone)) {
					this.$refs.phoneNumberRef.style.borderColor = 'red'
					this.getCodeBtnDisable = false
				} else {
					this.$refs.phoneNumberRef.style.borderColor = ''
					try {
						// let params = {}
						// params.phone = this.userInfo.phoneNumber
						// let data = await axios.post('/sendMessage', params)
						// this.$message.success('验证码发送成功')
						let that = this
						that.waitTime--
						that.getCodeBtnDisable = true
						this.codeBtnWord = `${this.waitTime}s 后重新发送`
						that.timer = setInterval(function () {
							if (that.waitTime > 1) {
								that.waitTime--
								that.codeBtnWord = `${that.waitTime}s 后重新发送`
							} else {
								clearInterval(that.timer)
								that.codeBtnWord = '获取验证码'
								that.getCodeBtnDisable = false
								that.waitTime = 60
							}
						}, 1000)
						this.getCodeFun()
					} catch (res) {
						console.log(res)
					}
				}
			}
		},
		async getCodeFun() {
			let phone = this.userInfo.phone
			const { data } = await axios.get('https://www.sdxinr.com/xrOfficialWebsite/appointmentUser/sms/' + phone)
			if (data.code === 200) {
				alert('验证码已发送！')
			} else {
				alert(data.msg)
			}
		}
	}
}
</script>

<style scoped lang="less">
.clearfix::before,
.clearfix::after {
	content: '';
	display: table;
	clear: both;
}

//直接设置所有输入框的样式
input[type='text'] {
	font-size: 14px;
	//font-family: PingFang SC;
	width: 240px;
	height: 31px;
	border: 1px solid #c6c9cf;
	border-radius: 5px;
	margin-bottom: 2px;
	color: white;
}

//姓名
.username {
	margin-left: 91px;
	margin-bottom: 15px;
	.username-top {
		margin-right: 15px;
	}
	.username-last {
		margin-right: 8.5px;
	}
	.inputUserName {
		font-size: 12px;
		font-family: 'PingFang-SC-Bold';
		font-weight: 500;
		color: #999999;
		line-height: 125px;
		padding-left: 11px;
	}
}

//手机号
.phoneNumber {
	margin-left: 91px;
	margin-bottom: 15px;
	.spanPhoneNumber {
		margin-right: 8.5px;
	}
	.inputPhoneNumber {
		font-size: 12px;
		font-weight: 500;
		color: #999999;
		// color: red;
		line-height: 125px;
		padding-left: 11px;
	}
}

//验证码
.checkCode {
	margin-left: 91px;
	margin-right: 8.5px;
	margin-bottom: 15px;
	.spanCheckCode {
		margin-right: 8.5px;
	}
	.inputCheckCode {
		width: 139px;
		height: 31px;
		padding-left: 11px;
		box-sizing: border-box;
		font-size: 12px;
		font-weight: 500;
		color: #999999;
		//border: 1px solid #C6C9CF;
		//border-radius: 5px;
	}
	.sendCheckCode {
		width: 106px;
		height: 31px;
		color: #2b80f4;
		border: 1px solid #2b80f4;
		border-radius: 5px;
		margin-left: 10px;
		// 鼠标小手
		cursor: pointer;
		background-color: #fff;
	}
}

//立即领取
.li-ji {
	margin-left: 92px;
}

.button-class {
	width: 314px;
	height: 31px;
	background: #2b80f4;
	border-radius: 5px;
	font-size: 14px;
	color: white;
	// 鼠标小手
	cursor: pointer;
	border: 0;
}

//设置全局的样式
.back-ground-picture {
	width: 100%;
	min-width: 1300px;
	margin: 0 auto;

	//设置标题的样式
	.back-ground-text {
		height: 41px;
		font-size: 40px;
		font-weight: bold;
		color: #333333;
		padding: 40px 0;
		//line-height: 125px;
		text-align: center;
		// font-family: 'PingFang-SC-Bold';
	}

	.back-ground-photo {
		width: 100%;
		height: 500px;
		background: url('@/assets/phoneuser/background-image-picture.png') no-repeat;
		background-size: cover;

		margin: 0 auto;

		.back-ground-from {
			width: 497px;
			height: 250px;
			background: #ffffff;
			border-radius: 15px;
			margin: 128px auto;

			.from-input-picture {
				width: 497px;
				margin-top: 40px;
			}
		}
	}
}
</style>
