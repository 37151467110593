<template>
	<div class="container">
		<div class="title">
			<h2>产品中心</h2>
			<p>PRODUCT CENTER</p>
		</div>
		<div class="product-core">
			<div class="content" @mouseover="mouseOver" @mouseleave="mouseLeave">
				<div
					:class="['content-child', contentActive === 1 ? 'content-child-active-first' : '']"
					@click="contentClick(1)"
				>
					<div class="content-title">
						<div class="icon"></div>
						<span>数字孪生平台</span>
					</div>
					<div class="text">现实与数字实时映射，双向交互</div>
				</div>
				<div
					:class="['content-child', contentActive === 2 ? 'content-child-active-second' : '']"
					@click="contentClick(2)"
				>
					<div class="content-title">
						<div class="icon"></div>
						<span>物联网平台</span>
					</div>
					<div class="text">万物互联，实时感知，精准管控</div>
				</div>
				<div
					:class="['content-child', contentActive === 3 ? 'content-child-active-third' : '']"
					@click="contentClick(3)"
				>
					<div class="content-title">
						<div class="icon"></div>
						<span>大数据平台</span>
					</div>
					<div class="text">数据清洗，数据分析，辅助决策</div>
				</div>
				<div
					:class="['content-child', contentActive === 4 ? 'content-child-active-fourth' : '']"
					@click="contentClick(4)"
				>
					<div class="content-title">
						<div class="icon"></div>
						<span>移动服务端平台</span>
					</div>
					<div class="text">多端融合，服务一线</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
export default {
	name: 'product-core',
	data() {
		return {
			contentActive: 1,
			mouseActive: 1
		}
	},
	methods: {
		contentClick(active) {
			this.contentActive = active
			this.mouseActive = active
		},
		mouseOver() {
			this.contentActive = 0
		},
		mouseLeave() {
			this.contentActive = this.mouseActive
		}
	}
}
</script>
<style lang="less" scoped>
.container {
	min-width: 1300px;
	width: 100%;
	margin: 0 auto;
}
.title {
	font-family: 'PingFang-SC-Bold';
	padding: 40px 0;
	text-align: center;
	h2 {
		font-size: 40px;
		color: #333;
	}
	p {
		font-size: 20px;
		color: #3370ff;
	}
}
.product-core {
	height: 460px;
	.content {
		width: 1200px;
		height: 100%;
		margin: auto;
		display: flex;
		flex-wrap: wrap;
		.content-child-active-first {
			background-image: url('~@/assets/images/product_center_hover1.png') !important;
			background-repeat: no-repeat !important;
			background-size: cover !important;
			.content-title {
				span {
					color: #fff !important;
				}
			}
			.text {
				color: #fff !important;
			}
		}
		.content-child-active-second {
			background-image: url('~@/assets/images/product_center_hover2.png') !important;
			background-repeat: no-repeat !important;
			background-size: cover !important;
			.content-title {
				span {
					color: #fff !important;
				}
			}
			.text {
				color: #fff !important;
			}
		}
		.content-child-active-third {
			background-image: url('~@/assets/images/product_center_hover3.png') !important;
			background-repeat: no-repeat !important;
			background-size: cover !important;
			.content-title {
				span {
					color: #fff !important;
				}
			}
			.text {
				color: #fff !important;
			}
		}
		.content-child-active-fourth {
			background-image: url('~@/assets/images/product_center_hover4.png') !important;
			background-repeat: no-repeat !important;
			background-size: cover !important;
			.content-title {
				span {
					color: #fff !important;
				}
			}
			.text {
				color: #fff !important;
			}
		}
		.content-child {
			&:nth-child(1) {
				background-image: url('~@/assets/images/product_center1.png');
				background-repeat: no-repeat;
				background-size: cover;
			}
			&:nth-child(2) {
				background-image: url('~@/assets/images/product_center2.png');
				background-repeat: no-repeat;
				background-size: cover;
			}
			&:nth-child(3) {
				background-image: url('~@/assets/images/product_center3.png');
				background-repeat: no-repeat;
				background-size: cover;
			}
			&:nth-child(4) {
				background-image: url('~@/assets/images/product_center4.png');
				background-repeat: no-repeat;
				background-size: cover;
			}
			box-sizing: border-box;
			margin: 10px;
			box-shadow: -2px 2px 15px rgba(232, 233, 239, 1);
			width: calc(50% - 20px);
			height: calc(50% - 20px);
			transition-duration: 0.5s;
			cursor: pointer;
			// background-color: antiquewhite;
			.content-title {
				display: flex;
				flex-wrap: wrap;
				.icon {
					margin-top: 44px;
					margin-left: 44px;
					width: 5px;
					height: 29px;
					border-radius: 3px;
					background-color: rgba(0, 214, 185, 1);
				}
				span {
					display: inline-block;
					margin-top: 36px;
					font-size: 30px;
					font-family: PingFang SC;
					font-weight: bold;
					color: #3370ff;
					margin-left: 15px;
				}
			}
			.text {
				margin-top: 20px;
				margin-left: 64px;
				font-family: PingFang SC;
				font-weight: 500;
				color: #666666;
			}
		}
		.content-child:hover {
			&:nth-child(1) {
				background-image: url('~@/assets/images/product_center_hover1.png');
				background-repeat: no-repeat;
				background-size: cover;
			}
			&:nth-child(2) {
				background-image: url('~@/assets/images/product_center_hover2.png');
				background-repeat: no-repeat;
				background-size: cover;
			}
			&:nth-child(3) {
				background-image: url('~@/assets/images/product_center_hover3.png');
				background-repeat: no-repeat;
				background-size: cover;
			}
			&:nth-child(4) {
				background-image: url('~@/assets/images/product_center_hover4.png');
				background-repeat: no-repeat;
				background-size: cover;
			}
			margin: 5px;
			width: calc(50% - 10px);
			height: calc(50% - 10px);
			border-radius: 1px;
			.content-title {
				span {
					color: #fff;
				}
			}
			.text {
				color: #fff;
			}
		}
	}
}
</style>
