<template>
	<div id="app">
		<home></home>
	</div>
</template>

<script>
import home from './views/home'

export default {
	name: 'App',

	components: {
		home
	}
}
</script>

<style lang="less">
#app {
	font-family: Avenir, Helvetica, Arial, sans-serif;

	-webkit-font-smoothing: antialiased;

	-moz-osx-font-smoothing: grayscale;

	color: #2c3e50;
}

* {
	margin: 0;

	padding: 0;

	list-style: none;
}
</style>
