<template>
	<div class="case">
		<div class="title">
			<h2>案例展示</h2>
			<p>CASE SHOW</p>
		</div>
		<div class="case-show">
			<div class="swiper mySwiper">
				<div class="swiper-wrapper">
					<div class="swiper-slide" v-for="(item, index) in caseShowData" :key="index">
						<div class="swiper-filter" :style="{ backgroundImage: 'url(' + item.img + ')' }"></div>
						<img :src="item.img" />
					</div>
				</div>
			</div>
			<div class="swiper-bottom">
				<div
					class="swiper-button-prev-case"
					:class="[swiperActive === 1 ? 'swiper-button-disabled' : '']"></div>
				<!--左箭头。如果放置在swiper外面，需要自定义样式。-->
				<div class="swiper mySwiper2">
					<div class="swiper-wrapper">
						<div class="swiper-slide" v-for="(item, index) in caseShowData" :key="index">
							<div class="swiper-button" :class="[swiperActive === index + 1 ? 'swiperActive' : '']">
								<p>{{ item.title1 }}</p>
								<p>{{ item.title2 }}</p>
							</div>
						</div>
					</div>
				</div>
				<div
					class="swiper-button-next-case"
					:class="[swiperActive === caseShowData.length ? 'swiper-button-disabled' : '']"></div>
				<!--右箭头。如果放置在swiper外面，需要自定义样式。-->
			</div>
		</div>
	</div>
</template>
<script>
export default {
	name: 'case-show',
	data() {
		return {
			swiperActive: 1,
			caseShowData: [
				{
					title1: '智慧医院数字孪生',
					title2: '综合管理平台',
					img: require('@/assets/images/case-show1.png')
				},
				{
					title1: '数字孪生智慧楼宇',
					title2: '管理平台',
					img: require('@/assets/images/case-show2.png')
				},
				{
					title1: '智慧园区ICO运营',
					title2: '指挥中心',
					img: require('@/assets/images/case-show3.png')
				},
				{
					title1: '智慧校园可视化',
					title2: '综合管理平台',
					img: require('@/assets/images/case-show4.png')
				},
				// {
				// 	title1: '香菇智慧',
				// 	title2: '产业园',
				// 	img: require('@/assets/images/case-show5.png')
				// },
				{
					title1: '矿业数字孪生',
					title2: '综合管理平台',
					img: require('@/assets/images/case-show6.png')
				}
			]
		}
	},
	mounted() {
		this.swiperInit()
	},
	methods: {
		// 轮播初始化
		swiperInit() {
			let _that = this
			let swiper = new Swiper('.mySwiper2', {
				spaceBetween: 10,
				slidesPerView: 4,
				freeMode: true,
				watchSlidesProgress: true
			})
			let swiper2 = new Swiper('.mySwiper', {
				loop: false,
				spaceBetween: 10,
				navigation: {
					nextEl: '.swiper-button-next-case',
					prevEl: '.swiper-button-prev-case'
				},
				thumbs: {
					swiper: swiper
				},
				on: {
					slideChangeTransitionStart: function () {
						_that.swiperActive = this.activeIndex + 1
					}
				}
			})
		}
	}
}
</script>
<style lang="less" scoped>
.case {
	min-width: 1300px;
	width: 100%;
	margin: 0 auto;
}
.title {
	font-family: 'PingFang-SC-Bold';
	padding: 40px 0;
	text-align: center;
	h2 {
		font-size: 40px;
		color: #333;
	}
	p {
		font-size: 20px;
		color: #3370ff;
	}
}
.case-show {
	min-width: 1300px;
	width: 100%;
	height: 925px;
	position: relative;
	.mySwiper {
		height: 735px;
		overflow: hidden;
		.swiper-slide {
			position: relative;
			min-width: 1024px;
			height: 100%;
			width: 100%;

			.swiper-filter {
				width: 100%;
				height: 100%;
				top: 0;
				left: 0;
				filter: blur(8px);
				position: absolute;
				// background-image: url('~@/assets/images/case-show1.png');
				background-repeat: no-repeat;
				background-size: cover;
				background-position: center;
			}
			img {
				height: 675px;
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
			}
		}
	}
	.swiper-bottom {
		height: 190px;
		display: flex;
		justify-content: center;
		.swiper-button-prev-case {
			width: 30px;
			height: 30px;
			margin: 65px 0px 0px 0px;
			background: url('~@/assets/images/prev.png') no-repeat;
			&:after {
				content: '';
			}
			&:hover {
				cursor: pointer;
				background: url('~@/assets/images/prev1.png') no-repeat;
			}
		}
		.swiper-button-disabled {
			pointer-events: none;
			opacity: 0.5;
		}
		.swiper-button-next-case {
			width: 30px;
			height: 30px;
			margin: 65px 0px 0px 0px;
			background: url('~@/assets/images/next.png') no-repeat;
			&:after {
				content: '';
			}
			&:hover {
				cursor: pointer;
				background: url('~@/assets/images/next1.png') no-repeat;
			}
		}
		.mySwiper2 {
			padding-top: 30px;
			padding-bottom: 30px;
			margin-left: 2.3%;
			margin-right: 2.3%;
			min-width: 1180px;
			max-width: 1181px;
			.swiper-slide {
				.swiperActive {
					background-color: rgba(51, 112, 255, 1) !important;
					color: #fff !important;
				}
				max-width: 280px;
				.swiper-button {
					height: 100%;
					padding-top: 25px;
					p {
						text-align: center;
						word-wrap: break-word;
						font-size: 19px;
						font-family: PingFang SC;
						font-weight: bold;
					}
					&:hover {
						cursor: pointer;
					}
					height: 75px;
					box-shadow: -2px 2px 15px rgba(232, 233, 239, 1);
				}
			}
		}
	}
}
</style>
